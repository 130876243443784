<script setup>
const props = defineProps({
  title: String,
  links: Array
})

const { mnetSiteUrl } = useUrls()

function getSiteUrl (path) {
  if (/http|#/.test(path)){
    return path
  }
  return mnetSiteUrl + path
}
</script>

<template>
  <div class="mb-4">
    <h3 class="font-family-raleway font-bold text-lg sm:text-xl mb-1 sm:mb-2">{{ props.title }}</h3>
    <ul>
      <slot name="additional-links" />
      <li v-for="link of links" :key="link.text" class="py-2 sm:py-0 text-sm lg:text-base footer-link">
        <a :href="getSiteUrl(link.url)" :class="link.class || ''" :target="link.target || '_self'">{{ link.text }}</a>
      </li>
    </ul>  
  </div>
</template>