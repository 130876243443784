<script setup>
import MainButton from '@/components/MainButton.vue'
import { RECHARGE_PATH } from '@/utils/constants'
import EuroIcon from '@/components/icons/EuroIcon.vue'
const props = defineProps({
  isDark: {
    type: Boolean,
    default: true
  },
  classList: {
    type: String,
    default: ""
  }
})

const { mnetSiteUrl } = useUrls()
</script>

<template>
  <MainButton :href="mnetSiteUrl + RECHARGE_PATH" :class="`group text-lg ${classList}`"
    :variant="`${isDark ? 'transparent-dark' : 'transparent-light'}`">
    <div class="flex flex-row items-center justify-center font-normal">
      <EuroIcon :class="`${isDark ? 'fill-dark group-hover:fill-white' : 'fill-white group-hover:fill-dark'}`"
        class="mr-1" />
      <span class="font-semibold text-lg">Ricarica</span>
    </div>
  </MainButton>
</template>
