<script setup>
import FooterLinkSection from '@/components/FooterLinkSection.vue'
import CorporateInfo from '@/components/CorporateInfo.vue'
import FeedatyWidget300x100 from '@/components/FeedatyWidget300x100.vue'
import FeedatyWidget200x150 from '@/components/FeedatyWidget200x150.vue'
import {
  VOIP_PATH,
  FAX_PATH,
  SMS_PATH,
  GLOSSARY_PATH
} from "@/utils/constants.js"

const { lyberSiteUrl, tcSiteUrl, mnetSiteUrl } = useUrls()

const INFORMATION_SECTION = 'Informazioni'
const CORPORATE_SECTION = 'Corporate'
const SHOP_ONLINE_SECTION = 'Shop online'
const CUSTOMERS_SECTION = 'Clienti'
const SOCIAL_SECTION = 'Social'

const FOOTER_LINK_SECTIONS = {
  [INFORMATION_SECTION]: [
    {
      url: '/legal/informativa.html',
      text: 'Informativa Privacy'
    },
    {
      url: '/legal/informativa_cookie.html',
      text: 'Informativa Cookie'
    },
    {
      url: '#',
      class: 'iubenda-cs-preferences-link',
      target: '_blank',
      text: 'Modifica preferenze cookie'
    },
    {
      url: '/legal/codice-condotta-alias.html',
      text: 'Codice di condotta Alias'
    },
    {
      url: '/cartaservizi/',
      text: 'Carta dei servizi e trasparenza tariffaria'
    },
    {
      url: '/legal/contratto.html',
      text: 'Contratti'
    },
    {
      url: '/legal/giustizia.html',
      text: 'Prestazioni di giustizia'
    },
    {
      url: '/legal/conciliaweb.html',
      text: 'Risoluzione delle controversie'
    },
    {
      url: RATES_PATH,
      text: 'Tariffe, canoni e abbonamenti'
    }
  ],
  [CORPORATE_SECTION]: [
    {
      url: ABOUT_PATH,
      text: 'Chi siamo'
    },
    {
      url: CAREERS_URL,
      text: 'Lavora con noi'
    },
    {
      url: CONTACT_PATH,
      text: 'Contatti'
    }
  ],
  [SHOP_ONLINE_SECTION]: [
    {
      url: lyberSiteUrl,
      text: 'Lyber'
    },
    {
      url: tcSiteUrl,
      text: 'Taglia Canone'
    }
  ],
  [CUSTOMERS_SECTION]: [
    {
      url: HELP_CENTER_URL,
      text: 'Help Center'
    },
    {
      url: GLOSSARY_PATH,
      text: 'Glossario VoIP e PBX'
    },
    {
      url: RATES_PATH,
      text: 'Tariffe Voip, Fax, SMS'
    },
    {
      url: RECHARGE_PATH,
      text: 'Ricarica'
    }
  ],
  [SOCIAL_SECTION]: [
    {
      url: LINKEDIN_URL,
      text: 'LinkedIn'
    },
    {
      url: FACEBOOK_URL,
      text: 'Facebook'
    },
    {
      url: TWITTER_URL,
      text: 'Twitter'
    }
  ]
}

const footerLinkSections = FOOTER_LINK_SECTIONS
const informationLinks = footerLinkSections[INFORMATION_SECTION]
const corporateLinks = footerLinkSections[CORPORATE_SECTION]
const shopOnlineLinks = footerLinkSections[SHOP_ONLINE_SECTION]
const customerLinks = footerLinkSections[CUSTOMERS_SECTION]
const socialLinks = footerLinkSections[SOCIAL_SECTION]
</script>

<template>
  <div class="footer-container bg-dark text-white py-8 sm:py-12">
    <div class="container main-container px-8 sm:px-4 md:px-0">
      <div class="grid sm:grid-cols-2 sm:gap-x-20 sm:gap-y-8 lg:grid-rows-2 lg:grid-cols-5 lg:gap-x-8 lg:gap-y-0 lg:justify-end border-b border-[#474747]">
        <div class="flex flex-col items-center gap-y-4 border-b border-white mb-8 sm:mb-0 sm:border-0 sm:w-fit sm:items-start lg:row-span-2 lg:col-span-2">
          <img alt="MessageNet" src="@/assets/images/logo-mnet-negativo.png" width="212" height="50" srcset="@/assets/images/logo-mnet-negativo.png 2x" class="">
          <CorporateInfo class="mb-8" />
          <FeedatyWidget300x100 class="hidden sm:block mb-8" />
          <FeedatyWidget200x150 class="block sm:hidden mb-8"/>
        </div>
        <FooterLinkSection class="lg:row-span-2" :title="INFORMATION_SECTION" :links="informationLinks" />
        <FooterLinkSection :title="CORPORATE_SECTION" :links="corporateLinks" class="order-1" />
        <FooterLinkSection :title="SHOP_ONLINE_SECTION" :links="shopOnlineLinks" class="order-2 sm:order-3">
          <template #additional-links>
            <li class="text-sm lg:text-base footer-link">
              <a :href="mnetSiteUrl+VOIP_PATH" class="hover:text-primary">VoIP</a>&nbsp;|
              <a :href="mnetSiteUrl+FAX_PATH" class="hover:text-primary">Fax</a>&nbsp;|
              <a :href="mnetSiteUrl+SMS_PATH" class="hover:text-primary">SMS</a>
            </li>
          </template>
        </FooterLinkSection>
        <FooterLinkSection :title="CUSTOMERS_SECTION" :links="customerLinks" class="order-3 sm:order-2" />
        <FooterLinkSection :title="SOCIAL_SECTION" :links="socialLinks" class="order-4" />        
      </div>
      <img alt="Metodi di pagamento accettati" src="@/assets/images/carte-sito.png" width="483" height="45" srcset="@/assets/images/carte-sito.png 2x" class="py-4 block mx-auto sm:ml-auto sm:mr-0">
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer-container :deep(li) {
  font-size: 15px;
  font-weight: regular;
  font-family: 'Source Sans Pro', Arial, sans-serif;
  a:hover {
    color: var(--primary);
  }
}
</style>