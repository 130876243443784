export const useUrls = () => {
  const config = useRuntimeConfig().public

  const lyberSiteUrl = config.NUXT_APP_LYBER_SITE_URL
  const tcSiteUrl = config.NUXT_APP_TC_SITE_URL
  const mnetSiteUrl = config.NUXT_APP_MNET_SITE_URL

  return {
    lyberSiteUrl,
    tcSiteUrl,
    mnetSiteUrl
  }
}