<template>
  <Disclosure as="nav" v-slot="{ open }" :class="{ 'show-fixed': showFixed, 'handle-fixed': handleFixed }">
    <div :class="[`absolute z-50 py-4 xl:py-8 w-full ${open || showFixed ? 'bg-white' : 'bg-transparent'}`, { 'shadow-lg': showFixed && !open },
    { 'open-menu': open }]">
      <div class="container nav-container">
        <div class="flex justify-between items-center">
          <div class="flex items-center gap-x-8">
            <a :href="mnetSiteUrl">
              <template v-if="isDark || open || showFixed">
                <img alt="MessageNet" src="@/assets/images/logo-mnet.png" width="212" height="50"
                  srcset="@/assets/images/logo-mnet.png 2x" class="hidden md:block">
                <img alt="MessageNet" src="@/assets/images/logo-mnet-mobile.svg" width="40" height="40"
                  class="block md:hidden">
              </template>
              <template v-else>
                <img alt="MessageNet" src="@/assets/images/logo-mnet-negativo.png" width="212" height="50"
                  srcset="@/assets/images/logo-mnet-negativo.png 2x" class="hidden md:block">
                <img alt="MessageNet" src="@/assets/images/logo-mnet-negativo-mobile.svg" width="40" height="40"
                  class="block md:hidden">
              </template>
            </a>
            <div
              :class="`hidden xl:flex xl:flex-row xl:gap-x-12 items-center xl:mx-6 ${isDark || showFixed ? 'text-dark' : 'text-white'}`">
              <a v-for="item in navigation" :key="item.name" :href="item.href" class="nav-link font-semibold">
                {{ item.name }}
              </a>
              <a class="nav-link" :href="helpCenterUrl">
                Help&nbsp;Center
              </a>
              <a class="nav-link is-current" href="/blog/">
                Blog
              </a>
            </div>
          </div>
          <div class="flex gap-x-4 items-center">
            <div class="hidden md:flex flex-row gap-x-2 xl:gap-x-4 md:items-center">
              <RechargeButton classList="px-4" :is-dark="open || isDark || showFixed" />
              <MainButton class="text-lg px-4" :href="getSiteUrl(USER_PATH)">
                <div class="flex justify-center items-center gap-x-1">
                  <AccountIcon class="my-auto fill-white" />
                  <span>Accedi</span>
                </div>
              </MainButton>
            </div>
            <!-- Mobile menu button-->
            <DisclosureButton class="inline-block xl:hidden items-center justify-center">
              <div v-if="open">
                <span class="sr-only">Chiudi il menu</span>
                <IconsClose class="w-[28px] h-[28px] md:w-[40px] md:h-[40px] fill-dark text-dark" />
              </div>
              <div v-else>
                <span class="sr-only">Apri il menu</span>
                <HamburgerMenu class="w-[28px] h-[28px] md:w-[40px] md:h-[40px]"
                  :class="{ ' text-white': !isDark && !showFixed, 'text-dark': isDark || showFixed }" />
              </div>
            </DisclosureButton>
          </div>
        </div>
      </div>
    </div>
    <DisclosurePanel class="absolute w-full xl:hidden bg-white top-0 right-0 min-h-screen z-40">
      <div class="container main-container px-6 flex flex-col gap-y-16">
        <div class="pt-[96px] relative flex flex-col items-start text-xl text-dark">
          <a v-for="item in navigation" :key="item.name" :href="item.href" class="py-2 font-semibold nav-body-l">
            {{ item.name }}
          </a>
          <a class="py-2 nav-body-l mt-4 is-current" href="/blog/">Blog</a>
          <a class="py-2 nav-body-l" :href="helpCenterUrl">Help Center</a>
        </div>
        <div class="flex md:hidden w-full flex-col mb-12 gap-y-2">
          <MainButton class="text-lg" :href="USER_PATH">
            <span class="flex justify-center items-center gap-x-1">
              <AccountIcon class="my-auto fill-white" />
              <span>Accedi</span>
            </span>
          </MainButton>
          <RechargeButton />
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue'
import MainButton from '@/components/MainButton.vue'
import RechargeButton from '@/components/RechargeButton.vue'
import {
  HELP_CENTER_URL,
  MENU_TYPE_DARK,
  RECHARGE_PATH,
  USER_PATH,
  VOIP_PATH,
  FAX_PATH,
  SMS_PATH,
  WS_PATH
} from '@/utils/constants'
import { useRoute } from 'vue-router'
import AccountIcon from '@/components/icons/AccountIcon.vue'
import EuroIcon from '@/components/icons/EuroIcon.vue'
import HamburgerMenu from '@/components/HamburgerMenu.vue'

const { mnetSiteUrl } = useUrls()
function getSiteUrl(path) {
  return mnetSiteUrl + path
}
const route = useRoute()
const menuType = route.meta.menuType || MENU_TYPE_DARK
const isDark = menuType === MENU_TYPE_DARK
const MIN_SCROLL_Y = 114
const showFixed = ref(false)
const handleFixed = ref(false)

let lastScrollY = 0

const handleScroll = () => {
  const currentScrollY = window.scrollY
  handleFixed.value = currentScrollY > MIN_SCROLL_Y

  if (currentScrollY < lastScrollY && currentScrollY > MIN_SCROLL_Y) {
    showFixed.value = true
  } else {
    showFixed.value = false
  }
  lastScrollY = currentScrollY
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})

const navigation = [
  {
    name: 'Voce',
    href: VOIP_PATH,
  },
  {
    name: 'Fax',
    href: FAX_PATH,
  },
  {
    name: 'SMS',
    href: SMS_PATH,
  },
  {
    name: 'Wholesale',
    href: WS_PATH
  }
]

const helpCenterUrl = HELP_CENTER_URL

const mobileButtons = [
  {
    label: "Ricarica",
    href: RECHARGE_PATH,
    component: EuroIcon
  },
  {
    label: "Accedi",
    href: USER_PATH,
    component: AccountIcon
  }
]
</script>

<style lang="scss" scoped>
nav {
  transition: top 0.5s ease-out, opacity 0.5s ease-out;

  &.handle-fixed {
    position: fixed;
    z-index: 1000;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 1;

    &:not(:has(.open-menu)) {
      top: -114px;
      opacity: 0;

      &.show-fixed {
        top: 0;
        opacity: 1;
      }  }
  }
}
</style>