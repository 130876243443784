<script setup>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

const route = useRoute()
const config = useRuntimeConfig().public
const mnetSiteUrl = config.NUXT_APP_MNET_SITE_URL
useHead(() => ({
  link: [
    {
      rel: 'canonical',
      href: mnetSiteUrl + route.path
    },
  ],
}))
</script>

<template>
  <div class="bg-white relative">
    <Navbar />
    <div class="pt-24 md:pt-32 mb-12">
      <slot />
    </div>
    <Footer></Footer>
  </div>
</template>
